import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import {
  Container,
  Header,
  InnerContainer,
  HalfBox,
} from '@lerna-monorepo/common/components/bigTextHeader/bigTextHeader.styled';
import {
  SectionContainer,
  ContentWidth,
} from '@lerna-monorepo/common/uiComponents';
import { LanguageSlug } from '@lerna-monorepo/common/enums/languageSlug.enum';
import { LocalStorageKeys } from '@lerna-monorepo/common/enums/localStorageKeys.enum';

import {
  SearchLabel,
  SearchValue,
  PageListSection,
  PageListItem,
  ArrowIcon,
  PageTitle,
  NavLink,
} from './search.styled';
import { SearchpageData } from './search.types';

const SearchPage: React.FC<SearchpageData> = (data: SearchpageData) => {
  const {
    currentLanguage,
    data: {
      translations: {
        noResults,
        resultsCounter,
        resultsCounter_2,
        resultsCounter_3,
        searchResultsFor,
      },
    },
  } = data.pageContext;
  const key = window.localStorage.getItem(LocalStorageKeys.Search);
  const searchPhrase = key ? JSON.parse(key) : '';
  const queryData = useStaticQuery(graphql`
    query {
      allSitePage {
        edges {
          node {
            id
            path
          }
        }
      }
    }
  `);

  const items = [];
  queryData.allSitePage.edges.filter(item => {
    const { id, path } = item.node;
    if (id.includes(searchPhrase.value) || path.includes(searchPhrase.value)) {
      const languagePrefix = path.split('/');
      if (
        id.includes('404') ||
        path === '/' ||
        path === `/${currentLanguage}/` ||
        data.location.pathname === path
      ) {
        return;
      }
      if (languagePrefix[1] === currentLanguage) {
        items.push(item.node);
      } else if (
        currentLanguage === LanguageSlug.Pl &&
        languagePrefix[1] !== LanguageSlug.Ru &&
        languagePrefix[1] !== LanguageSlug.De &&
        languagePrefix[1] !== LanguageSlug.En
      ) {
        items.push(item.node);
      }
    }
  });

  const getResultsPrefix = (quantity: number) => {
    if (quantity === 0) {
      return noResults;
    } else if (quantity === 1) {
      return resultsCounter_2.replace(/{{COUNT}}/, '');
    } else if (quantity <= 4) {
      return resultsCounter_3.replace(/{{COUNT}}/, items.length.toString());
    } else {
      return resultsCounter.replace(/{{COUNT}}/, items.length.toString());
    }
  };

  const renderLinks = () => {
    return items.map(({ id, path }: { id: string; path: string }) => {
      const valuesArr = path.split('/').map(item => item.split('-').join(' '));
      const title =
        currentLanguage !== LanguageSlug.Pl
          ? valuesArr.splice(2, valuesArr.length - 2).join(' ')
          : valuesArr.join(' ');
      return (
        <NavLink to={path} key={id}>
          <PageListItem>
            <ArrowIcon />
            <PageTitle>{title}</PageTitle>
          </PageListItem>
        </NavLink>
      );
    });
  };

  return (
    <>
      <Container>
        <InnerContainer>
          <Header>
            <SearchLabel>{searchResultsFor}</SearchLabel>
            <SearchValue>{searchPhrase.value}</SearchValue>
          </Header>
        </InnerContainer>
        <HalfBox />
      </Container>
      <PageListSection>
        <SectionContainer
          header={getResultsPrefix(items.length)}
          contentWithMode={ContentWidth.FullWidth}
        >
          {renderLinks()}
        </SectionContainer>
      </PageListSection>
    </>
  );
};

export default SearchPage;
